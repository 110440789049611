<template>
  <div class="m-1">
    <div v-if="component == 1">
      <h6 class="pt-2">Add details</h6>
      <b-form @submit.prevent="Continue">
        <validation-observer class="w-100" ref="lessonForm" #default="{invalid}">
          <validation-provider
            #default="{ errors }"
            name="Start Date"
            vid="start_date"
            rules="required"
          >
            <b-form-group label="Select a start date" label-size="lg" label-for="start_date">
              <b-input-group :class="$store.state.appConfig.layout.skin === 'dark' ? 'border-dark rounded-lg w-25' : 'bg-white rounded-lg w-25'">
                <flat-pickr
                  v-model="form.start_date"
                  id="start_date"
                  class="form-control"
                  placeholder="Start Date (DD-MM-YYYY)"
                  :config="{ dateFormat: 'd/m/Y'}"
                />
              </b-input-group>
              <small class="text-danger float-left">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <br />
          <div class="w-100">
            <b-row>
              <b-col>
                <b-card tag="article" class="mb-2 border-0 custom-card">
                  <b-card-text class="custom-card-content">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                      <p class="mb-0">Select your students</p>
                      <b-form-checkbox
                        @change="toggleSelectAll"
                        class="pink-checkbox"
                        v-model="selectAll"
                      >Select all students</b-form-checkbox>
                    </div>
                    <b-row>
                      <b-col
                        cols="12"
                        sm="6"
                        md="3"
                        v-for="student in students"
                        :key="student.id"
                        class="mb-2"
                      >
                        <div class="d-flex align-items-center">
                          <b-form-checkbox
                            class="pink-checkbox"
                            v-model="selectedUsers[student.id]"
                          ></b-form-checkbox>
                          <img
                            :src="student.avatar"
                            alt="Image"
                            class="student-image rounded-circle mr-2"
                          />
                          <p class="mb-0 mr-2">{{ student.fname }} {{ student.lname }}</p>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <div class="d-flex justify-content-between">
            <p></p>
            <b-button
              class="btn rounded-pill btn-sm pl-3 pr-3"
              variant="primary"
              @click="Continue"
            >Continue</b-button>
          </div>
        </validation-observer>
      </b-form>
    </div>
    <div v-if="component == 2">
      <h6 class="pt-2">Confirmation</h6>

      <b-row class="mt-5">
        <b-col cols="8">
          <div :class="$store.state.appConfig.layout.skin === 'dark' ? 'border-dark rounded-lg p-1' : 'bg-white rounded-lg p-1'">
            <h3>Details</h3>
            <div class="d-flex">
              <div>
                <p>Start date</p>
                <h3>{{form.start_date}}</h3>
              </div>
              <div class="ml-5">
                <p>Amount of students selected</p>
                <h3>{{ selectedStudents().length }}</h3>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <b-card :class="$store.state.appConfig.layout.skin === 'dark' ? 'border-dark' : ''">
              <h3>Selected Students</h3>
              <b-row class="mt-2">
                <b-col
                  cols="12"
                  v-for="student in selectedStudents()"
                  :key="student.id"
                  class="mb-2"
                >
                  <div class="d-flex align-items-center">
                    <img
                      :src="student.avatar"
                      alt="Student Image"
                      class="student-image rounded-circle mr-2"
                    />
                    <p class="mb-0 mr-2">{{ student.fname +' '+ student.fname }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-col>
        <b-col cols="4">
          <div v-if="item" :class="$store.state.appConfig.layout.skin === 'dark' ? 'border-dark rounded-lg p-2' : 'bg-white rounded-lg p-2'">
            <b-row class="px-25">
              <b-col md="12" class="mb-2">
                <img :src="item.cover_image" width="100%" alt class="rounded-lg" />
              </b-col>
              <b-col md="12" class="mb-2">
                <h3>{{item.name}}</h3>
                <div class="text-primary">
                  <feather-icon icon="FileTextIcon" size="16" class="cursor-pointer" />
                  <span>{{item.total_topics}} lessons</span>
                </div>
              </b-col>
              <b-col md="12" class="mb-2">
                <h3>Description</h3>
                <p>{{item.description}}</p>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-between mt-2">
        <div></div>
        <div>
          <b-button
            class="btn rounded-pill btn-sm pl-3 pr-3 mr-3"
            variant="outline-primary"
            @click="Back"
          >Back</b-button>
          <b-button
            class="btn rounded-pill btn-sm pl-3 pr-3"
            variant="primary"
            @click="Confirm"
          >Confirm</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  VBTooltip,
  BForm,
  BToast,
  BProgress,
  BFormFile,
  BFormCheckbox
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { mapGetters } from "vuex";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormFile,
    BToast,
    BProgress,
    vSelect,
    flatPickr,
    required,
    BFormCheckbox
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  computed: {
    ...mapGetters({
      item: "modules/item",
      assignedStudents: "lessonPlans/usersSchool",
      selected: "lessonPlans/usersModule",
      school_id: "lessonPlans/school_id",
      uploadProgress: "app/uploadProgress"
    }),
    id() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
    course_id() {
      return this.$route.query.course_id ? this.$route.query.course_id : null;
    }
  },
  data() {
    return {
      component: 1,
      showToast: false,
      form: {
        start_date: null
      },
      selectAll: false,
      students: [],
      selectedUsers: []
    };
  },
  methods: {
    init() {
      this.$store.dispatch("lessonPlans/users_lookup", this.id).then(_ => {
        this.students = this.assignedStudents;
        this.students.forEach(student => {
          this.$set(
            this.selectedUsers,
            student.id,
            this.selected.includes(student.id)
          );
        });
      });
      this.$store.dispatch("modules/get", this.id).then(_ => {});
    },

    toggleSelectAll() {
      this.students.forEach(student => {
        this.$set(this.selectedUsers, student.id, this.selectAll);
      });
    },
    selectedStudents() {
      return this.students.filter(student => this.selectedUsers[student.id]);
    },
    Continue() {
      this.$refs.lessonForm.validate().then(success => {
        if (success) {
          if (this.selectedStudents().length == 0) {
            this.$swal({
              icon: "error",
              title: `<h4>No Users Selected</h4>`,
              showConfirmButton: true,
              confirmButtonColor: "#E84185",
              allowOutsideClick: true
            });
          }
          if (this.selectedStudents().length > 0) {
            this.component = 2;
          }
        }
      });
    },
    Back() {
      this.component = 1;
    },
    validUserIds() {
      return this.selectedUsers.reduce((result, userId, index) => {
        if (userId === true) {
          result.push(index);
        } else if (Number.isInteger(userId) && userId > 0) {
          result.push(userId);
        }
        return result;
      }, []);
    },
    Confirm() {
      const payload = {
        id: this.item.id,
        query: {
          userIds: this.validUserIds(),
          schoolId: this.school_id,
          startDate: this.handleDateEditFormat(this.form.start_date)
        }
      };
      this.$store
        .dispatch("lessonPlans/assignUsers", payload)
        .then(res => {
          this.$router.push({ name: "learning" });
        })
        .catch(error => {
          if (error.response) {
            this.$swal({
              icon: "error",
              title: `<h4>${error.response.data.error}</h4>`,
              showConfirmButton: true,
              confirmButtonColor: "#E84185",
              allowOutsideClick: true
            });
          }
        });
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss">
// @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
// @import '@core/scss/vue/libs/chart-apex.scss';
@import "@core/scss/vue/libs/vue-flatpicker.scss";
[dir] .custom-file-label {
  border: unset !important;
  background: transparent !important;
  border-bottom: 1px solid #d8d6de !important;
  cursor: pointer !important;
}
input.is-invalid:focus ~ .custom-file-label {
  box-shadow: unset !important;
}

[dir] .custom-file-label::after {
  padding: 0.6rem 1rem !important;
  background-color: transparent !important;
  color: white !important;
}
.custom-file-label::after {
  border-radius: 0.357rem !important;
}

.custom-file-label::after {
  content: "" !important; /* Optional: Customize the text displayed on hover */
  cursor: pointer !important; /* Indicate clickable area */
  /* Add padding or other styles for a custom look */
}
.preview-module {
  background-color: #f7f8fa;
  border: #d8d6de 1px;
}
.student-image {
  border: 2px solid rgb(249, 173, 185);
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
</style>
